import Footer from "./Components/Footer";
import Header from "./Components/Header";
import MainBody from "./Components/MainBody";
import "./index.css";

function App() {
  return (
    <div className="h-screen">
      <Header />

      <MainBody />

      <Footer />
    </div>
  );
}

export default App;
