import React, { useRef, useState } from "react";
import Modal from "./Modal";

function Input() {
  const [file, setFile] = useState();
  const [showModal, setShowModal] = useState(false);
  const [objectUrl, setObjectUrl] = useState("");
  const [fontName, setFontName] = useState("");
  const inputRef = useRef();

  const hasFiles = ({ dataTransfer: { types = [] } }) =>
    types.indexOf("Files") > -1;

  const onDragOver = (event) => {
    if (hasFiles(event)) {
      event.preventDefault();
    }
  };

  const onDragEnter = (event) => {
    if (!hasFiles(event)) {
      return;
    }
  };

  const onFileDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setObjectUrl(event.dataTransfer.files[0]);
    setFile(URL.createObjectURL(event.dataTransfer.files[0]));
  };

  function onFileChange(e) {
    setObjectUrl(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  }

  const handleSubmit = (e) => {
    const formData = new FormData();
    formData.append("image", file);

    fetch("http://127.0.0.1:5000/submit", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setFontName(data.fontName);
        setShowModal(true);
      })
      .catch((err) => console.error(err));

    // setShowModal(true);
  };

  return (
    <section>
      {/* component */}
      <div className="sm:px-8 md:px-16 sm:py-8">
        <main className="mx-auto">
          {/* file upload modal  */}
          <article
            aria-label="File Upload Modal"
            className="flex flex-col bg-white shadow-xl rounded-md"
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDrop={onFileDrop}
          >
            {/* overlay  */}
            <div
              id="overlay"
              className="pointer-events-none flex flex-col items-center justify-center rounded-md"
            >
              <i>
                <svg
                  className="fill-current w-12 h-12 mb-3 text-blue-700"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z" />
                </svg>
              </i>
              <p className="text-lg text-blue-700">Drop files to upload</p>
            </div>

            {/* scroll area  */}
            <section className="px-8 py-2 flex flex-col">
              <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
                <p className="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">
                  <span>Drag and drop your</span>&nbsp;
                  <span>files here or</span>
                </p>
                {/* <input ref={inputRef} accept="image/*" type="file" className="hidden" alt="Font Image"/> */}

                <label htmlFor="my-file" title="Click to choose a file">
                  {/* <img src="https://picsum.photos/200/300" width="100" height="100"/> */}
                </label>
                <input
                  type="file"
                  id="my-file"
                  ref={inputRef}
                  className="hidden"
                  accept="image/*"
                  onChange={(e) => onFileChange(e)}
                />

                <button
                  onClick={() => inputRef.current.click()}
                  id="button"
                  className="mt-2 rounded-sm px-3 py-1 bg-gray-600 hover:bg-gray-400 focus:shadow-outline focus:outline-none"
                >
                  Select a file
                </button>
              </header>

              <div id="gallery" className="m-1">
                {!file ? (
                  <div
                    id="empty"
                    className="h-12 text-center flex flex-col justify-center items-center"
                  >
                    <div className="text-center flex flex-col justify-center items-center ">
                    <img
                      className="mx-auto w-12 mt-14"
                      src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                      alt="no data"
                    />
                  
                  </div>
                    <span className="text-small text-gray-500">
                      No files selected
                    </span>
                  </div>
                ) : (
                  <div className="text-center flex flex-col justify-center items-center ">
                    <img
                      className="mx-auto w-24 h-16 object-contain border border-blue-900"
                      src={objectUrl}
                      alt="no data"
                    />
                </div>
                )}
              </div>
            </section>

            {/* sticky footer  */}
            <div className="flex justify-end px-8 pb-8 pt-4 mt-4">
              <button
                onClick={handleSubmit}
                className="rounded-sm px-3 py-1 bg-blue-700 hover:bg-blue-500 text-white focus:shadow-outline focus:outline-none"
                type="button"
                data-modal-toggle="popup-modal"
              >
                Check
              </button>

              {showModal && (
                <Modal
                  showModal={showModal}
                  setShowModal={setShowModal}
                  objectUrl={objectUrl}
                  setFile={setFile}
                  fontName={fontName}
                  setFontName={setFontName}
                />
              )}

              <button
                id="cancel"
                className="ml-3 rounded-sm px-3 py-1 bg-gray-500 focus:shadow-outline focus:outline-none"
                onClick={() => setFile(null)}
              >
                Cancel
              </button>
            </div>
          </article>
        </main>
      </div>
    </section>
  );
}

export default Input;
