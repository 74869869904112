import React from "react";

function Modal({
  showModal,
  setShowModal,
  setFile,
  objectUrl,
  fontName,
  setFontName,
}) {
  return (
    <div className="">
      <div
        id="popup-modal"
        className="fixed top-0 right-0 flex items-center justify-center bg-[rgba(0,0,0,0.7)] left-0 z-50 h-modal md:inset-0 md:h-full"
      >
        <div className="p-4 w-full max-w-md h-full md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              onClick={() => {
                setShowModal(false);
                setFile(null);
                URL.revokeObjectURL(objectUrl);
              }}
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-toggle="popup-modal"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
            <div className="p-6 text-center">
              <div className="flex justify-center">
                <img
                  src={objectUrl}
                  className="h-32 w-28 text-center object-contain"
                  alt="Font Image"
                />
              </div>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                <p className="text-xl text-white">Font Name: {fontName}</p>
              </h3>
              <button
                onClick={() => {
                  setShowModal(false);
                  setFile(null);
                  URL.revokeObjectURL();
                }}
                data-modal-toggle="popup-modal"
                type="button"
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
              >
                Got It
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
