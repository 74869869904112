import React from "react";

function Intro() {



  return (
    <div className="max-h-fit px-4 py-32 mx-auto lg:items-center lg:flex xl:h-[82.5vh] lg:h-[77.2vh]">
      <div className="w-3xl mx-auto text-center">
        <h1 className="leading-normal lg:text-5xl font-extrabold h-fit text-transparent sm:text-5xl bg-clip-text bg-gradient-to-r from-green-300 via-blue-500 to-purple-600">
          Font Detection
          <br />
          With Transfer Learning
        </h1>

        <p className="mx-auto mt-5 sm:leading-relaxed leading-5 sm:text-xl">
          Upload your font image we will let you know bangla font name.
        </p>

        <div className="flex flex-wrap justify-center gap-4 mt-8">
          <a
            className="block w-full px-12 py-3 text-sm font-medium text-white bg-blue-600 border border-blue-600 rounded sm:w-auto active:text-opacity-75 hover:bg-transparent hover:text-white focus:outline-none focus:ring"
            href="/get-started"
          >
            Get Started
          </a>

          <a
            className="block w-full px-12 py-3 text-sm font-medium text-white border border-blue-600 rounded sm:w-auto hover:bg-blue-600 active:bg-blue-500 focus:outline-none focus:ring"
            href="/about"
          >
            Learn More
          </a>
        </div>
      </div>
    </div>
  );
}

export default Intro;
