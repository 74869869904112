import React from "react";

function Footer() {
  return (
    <footer className="bg-gray-900">
      <div className="max-w-screen-xl px-4 py-3 mx-auto sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div className="flex text-3xl justify-center text-blue-500 sm:justify-start">
            <h3><span className="text-red-600">OkkhOr</span></h3>
          </div>

          <p className="mt-4 text-sm text-center text-gray-100 lg:text-right lg:mt-0">
            Copyright &copy; 2022. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
