import React from "react";

function Header() {
  return (
    <header className="bg-gray-900">
      <div className="flex items-center h-16 max-w-screen-xl gap-8 px-4 mx-auto sm:px-6 lg:px-8">
        <a className="block text-blue-300 text-3xl sm:2xl" href="/">
          <h3><span className="text-red-600">অক্ষর</span> - Bangla Font Detector</h3>
        </a>

        <div className="flex items-center justify-end flex-1 md:justify-between">
          <nav className="hidden md:block">
            <h2 className="sr-only" id="header-navigation">
              Header navigation
            </h2>

            <ul className="flex items-center gap-6 text-lg">
              <li>
                <a
                  className="text-white transition hover:text-blue-500/75"
                  href="/"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  className="text-white transition hover:text-blue-500/75"
                  href="/about"
                >
                  About
                </a>
              </li>

              <li>
                <a
                  className="text-white transition hover:text-blue-500/75"
                  href="/team"
                >
                  Team Info
                </a>
              </li>
            </ul>
          </nav>

          <div className="flex items-center gap-4">
            <button className="block p-2.5 text-gray-600 transition bg-gray-100 rounded md:hidden hover:text-gray-600/75">
              <span className="sr-only">Toggle menu</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
