import React from "react";
import Input from "./Input";
import Intro from "./Intro";

function MainBody() {
  return (
    <section className="relative text-white min-h-[calc(100vh-124px)] bg-gray-900">
      <div className="grid lg:grid-cols-2 md:grid-cols-1 xs:grid-cols-1 sm:grid-cols-1 items-center content-center max-w-screen-xl gap-1 px-4 mx-auto">

      <Intro/>
      <Input/>



      </div>
    </section>
  );
}

export default MainBody;
